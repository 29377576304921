import React from 'react';
import Paper from '@mui/material/Paper';
import makeStyles from '@mui/styles/makeStyles';
import { DataGrid } from '@mui/x-data-grid';
import moment from 'moment';
import { colors } from '../lib/styles';

const useStyles = makeStyles({
  tintedBackground: {
    backgroundColor: colors.blackOpacity,
  },
  gridContainer: {
    width: '100%',
    display: 'flex',
    height: 350,
  },
  linkBtn: {
    textDecoration: 'none',
    color: colors.black,
  },
});

const Subscriptions = (props) => {
  const classes = useStyles();
  const { data } = props;

  const columns = [
    { field: 'id', hide: true },
    {
      field: 'status',
      headerName: 'Status',
      align: 'left',
      width: 250,
    },
    {
      field: 'amount',
      headerName: 'Amount',
      align: 'right',
      width: 150,
      renderCell: (params) => {
        const { items } = params.row;
        if (!items || !items.data || !items.data.length || !items.data[0].price) {
          return 0;
        }
        return (Number((items.data[0].price.unit_amount || 0) / 100).toFixed(2));
      },
    },
    {
      field: 'start_date',
      headerName: 'date',
      align: 'right',
      width: 150,
      renderCell: (params) => {
        const { start_date } = params.row;
        const dateStr = moment(start_date * 1000).format('MM/DD/YYYY');
        return dateStr;
      },
    },
  ];

  return (
    <div style={classes.mainBody}>
      <Paper className={classes.tintedBackground}>
        <div className={classes.gridContainer}>
          <DataGrid columns={columns} rows={data || []} />
        </div>
      </Paper>
    </div>
  );
};

export default Subscriptions;
