import React from 'react';
import { connect } from 'react-redux';

import Typography from '@mui/material/Typography';

const baseStyles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    textAlign: 'center',
  },
  mainBody: {
    display: 'flex',
    height: '100%',
  },
};

const App = (props) => {
  const { user } = props;
  return (
    <div style={baseStyles.container}>
      <div style={baseStyles.container}>
        <Typography variant="h4">Welcome, {user?.auth?.email}</Typography>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const { user } = state;
  return { user };
}

export default connect(mapStateToProps)(App);
