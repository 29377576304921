/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import {
  Tab,
  Tabs,
} from '@mui/material';

import { apiFetch } from '../lib/fetch';
import { colors, Button } from '../lib/styles';
import Directs from '../components/directs';
import Billing from '../components/billing';
import Subscriptions from '../components/subscriptions';
import AgentNotifications from '../components/agent_notifications';
import MissedMoneyYear from '../components/missed_money_year';
import MissedMoneyYearPillar from '../components/missed_money_year_pillar';
import MissedMoneyMonth from '../components/missed_money_month';
import MissedMoneyMonthPillar from '../components/missed_money_month_pillar';
import config from '../config';
import Layout from '../components/layout';

const useStyles = makeStyles({
  container: {
    margin: 10,
  },
  loadingCircle: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '100px',
  },
  sponsor: {
    color: colors.black,
  },
  delSponsor: {
    color: 'red',
    textDecoration: 'line-through',
  },
  user: {
    color: colors.black,
  },
  delUser: {
    color: 'red',
    textDecoration: 'line-through',
  },
  linkBtn: {
    textDecoration: 'none',
    color: colors.black,
  },
  section: {
    backgroundColor: colors.white,
    margin: '15px',
    padding: '5px',
    color: colors.black,
    borderRadius: '5px',
  },
  label: {
    color: colors.black,
    margin: '5px',
  },
  tab: {
    fontWeight: 'bold',
    fontSize: '0.9em',
    textTransform: 'none',
  },
});

const User = () => {
  const [user, setUser] = useState(null);
  const [tabIndex, setTabIndex] = useState(0);
  const [billing, setBilling] = useState([]);
  const [subscriptions, setSubscriptions] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [mmYear, setMmYear] = useState([]);
  const [mmYearPillar, setMmYearPillar] = useState([]);
  const [mmMonth, setMmMonth] = useState([]);
  const [mmMonthPillar, setMmMonthPillar] = useState([]);
  const location = useParams();
  const { userId } = location;

  function findUser(id) {
    return apiFetch(`/users/${id}/stats`, { method: 'GET' });
  }

  useEffect(async () => {
    setUser(await findUser(userId));
    const billingUrl = `/stripe_log?orderBy=created_at&limit=5000&agent=${userId}`;
    const subscriptionsUrl = `/users/${userId}/billing/subscriptions`;
    const notificationsUrl = `/sent_notifications?orderBy=created_at&limit=5000&agent_id=${userId}`;
    const mmYearUrl = `/missed_money/year?limit=5000&to_agent=${userId}`;
    const mmYearPillarUrl = `/missed_money/year/pillar?limit=5000&to_agent=${userId}`;
    const mmMonthUrl = `/missed_money/month?limit=5000&to_agent=${userId}`;
    const mmMonthPillarUrl = `/missed_money/month/pillar?limit=5000&to_agent=${userId}`;
    setBilling(await apiFetch(billingUrl));
    setSubscriptions(await apiFetch(subscriptionsUrl));
    setNotifications(await apiFetch(notificationsUrl));
    setMmYear(await apiFetch(mmYearUrl));
    setMmYearPillar(await apiFetch(mmYearPillarUrl));
    setMmMonth(await apiFetch(mmMonthUrl));
    setMmMonthPillar(await apiFetch(mmMonthPillarUrl));
  }, []);

  const classes = useStyles();
  let directs = 0;
  let qualified = 0;
  if (user) {
    user.directsBusiness.forEach((d) => {
      directs++;
      if (d.fbs >= 5000) {
        qualified++;
      }
    });
  }

  return (
    <Layout headerTitle="User Overview">
      <div className={classes.container}>
        { user ? (
          <div>
            <div>
              <Typography variant="h3">
                <div>
                  <span className={user.is_deleted ? classes.delUser : classes.user}>
                    {user.firstname} {user.lastname} #{user.num} {user.email}
                  </span>
                  <Button gold sx={{ margin: '15px' }}>
                    <Link className={classes.linkBtn} to={`/users/${user.id}/edit`}>Edit</Link>
                  </Button>
                  <Button
                    gold
                    sx={{ margin: '15px' }}
                    onClick={() => {
                      document.location.href = `${config.API_URL}/users/p/${user.id}`;
                    }}
                  >
                    Switch
                  </Button>
                </div>
                {user.orig_email ? (
                  <div>
                    <div>Original Email: {user.orig_email}</div>
                  </div>
                ) : ''}
              </Typography>
              {user.sponsor ? (
                <Typography variant="h5">
                  <div>Sponsor:
                    <Link
                      to={`/users/${user.sponsor.id}/overview`}
                      className={user.sponsor.is_deleted ? classes.delSponsor : classes.sponsor}
                    >
                      {user.sponsor.firstname} {user.sponsor.lastname} #{user.sponsor.num}
                    </Link>
                  </div>
                </Typography>
              ) : ''}
              {user.termination_date ? (
                <Typography variant="h5">
                  <div>Termination Date: {user.termination_date.substring(0, 10)}</div>
                </Typography>
              ) : ''}
            </div>
            <div className={classes.section}>
              <Typography variant="h5">Sales</Typography>
              {user.start_date ? (
                <div style={{ marginBottom: '10px' }}>
                  <div>Start Date: {user.original_start.substring(0, 10)}</div>
                  <div>Current Period: {user.start_date.substring(0, 10)} - now</div>
                </div>
              ) : ''}
              <div className={classes.label}>
                Current Sales: {Number(user.currentSales).toFixed(2)}
              </div>
              <div className={classes.label}>
                Current Wealthshare: {Number(user.currentWealthshare).toFixed(2)}
              </div>
            </div>
            <Tabs
              value={tabIndex}
              onChange={(e, v) => {
                setTabIndex(v);
              }}
              centered
            >
              <Tab label="Directs" className={classes.tab} />
              <Tab label="Billing" className={classes.tab} />
              <Tab label="Subscriptions" className={classes.tab} />
              <Tab label="Notifications" className={classes.tab} />
              <Tab label="MM - Year" className={classes.tab} />
              <Tab label="MM - Year & Pillar" className={classes.tab} />
              <Tab label="MM - Month" className={classes.tab} />
              <Tab label="MM - Month & Pillar" className={classes.tab} />
            </Tabs>
            <div
              className={classes.section}
              style={{ display: tabIndex === 0 ? '' : 'none' }}
            >
              <Typography variant="h5">Directs</Typography>
              <div className={classes.label} style={{ margin: '5px' }}>
                Directs: {directs} Qualified: {qualified}
                <Link to={`/network/${userId}`} style={{ color: colors.black, margin: '10px' }}>Full Network</Link>
              </div>
              <Directs data={user.directsBusiness} />
            </div>
            <div
              className={classes.section}
              style={{ display: tabIndex === 1 ? '' : 'none' }}
            >
              <Typography variant="h5">Billing</Typography>
              <Billing data={billing} />
            </div>
            <div
              className={classes.section}
              style={{ display: tabIndex === 2 ? '' : 'none' }}
            >
              <Typography variant="h5">Subscriptions</Typography>
              <Subscriptions data={subscriptions} />
            </div>
            <div
              className={classes.section}
              style={{ display: tabIndex === 3 ? '' : 'none' }}
            >
              <Typography variant="h5">Notifications</Typography>
              <AgentNotifications data={notifications} />
            </div>
            <div
              className={classes.section}
              style={{ display: tabIndex === 4 ? '' : 'none' }}
            >
              <Typography variant="h5">Missed Money by Year</Typography>
              <MissedMoneyYear data={mmYear} />
            </div>
            <div
              className={classes.section}
              style={{ display: tabIndex === 5 ? '' : 'none' }}
            >
              <Typography variant="h5">Missed Money by Year and Pillar</Typography>
              <MissedMoneyYearPillar data={mmYearPillar} />
            </div>
            <div
              className={classes.section}
              style={{ display: tabIndex === 6 ? '' : 'none' }}
            >
              <Typography variant="h5">Missed Money by Month</Typography>
              <MissedMoneyMonth data={mmMonth} />
            </div>
            <div
              className={classes.section}
              style={{ display: tabIndex === 7 ? '' : 'none' }}
            >
              <Typography variant="h5">Missed Money by Month and Pillar</Typography>
              <MissedMoneyMonthPillar data={mmMonthPillar} />
            </div>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              <div className={classes.section}>
                <Typography variant="h5">
                  <Link
                    to={`/users/${user.id}/bsfs`}
                    style={{ color: colors.black, margin: '10px' }}
                  >
                    BSFs
                  </Link>
                </Typography>
                <div className={classes.label}>
                  Writer: {user.bsf_count}
                </div>
                {user.bsf_latest ? (
                  <div style={{ margin: '10px' }}>
                    Latest:
                    <Link
                      to={`/bsfs/${user.bsf_latest}`}
                      style={{ color: colors.black, margin: '10px' }}
                    >
                      {user.bsf_created.substring(0, 10)}
                    </Link>
                  </div>
                ) : ''}
                <div className={classes.label}>
                  On splits: {user.split_count}
                </div>
              </div>

              <div className={classes.section}>
                <Typography variant="h5">
                  <Link
                    to={`/users/${user.id}/payments`}
                    style={{ color: colors.black }}
                  >
                    Agent Payments
                  </Link>
                </Typography>
                <div className={classes.label}>
                  Count: {Number(user.pay_count)}
                </div>
                <div className={classes.label}>
                  Total: ${Number(user.pay_total).toFixed(2)}
                </div>
                {user.pay_created ? (
                  <div style={{ color: colors.black, margin: '10px' }}>
                    Latest: {user.pay_created.substring(0, 10)}
                  </div>
                ) : ''}
              </div>
              <div className={classes.section}>
                <Typography variant="h5">
                  <Link
                    to={`/users/${user.id}/overrides`}
                    style={{ color: colors.black }}
                  >
                    Wealthshare Payout
                  </Link>
                </Typography>
                <div className={classes.label}>
                  Count: {Number(user.overpay_count)}
                </div>
                <div className={classes.label}>
                  Total: ${Number(user.overpay_total).toFixed(2)}
                </div>
                {user.overpay_created ? (
                  <div style={{ margin: '10px' }}>
                    Latest: {user.overpay_created.substring(0, 10)}
                  </div>
                ) : ''}
              </div>

              <div className={classes.section}>
                <Typography variant="h5">
                  <Link
                    to={`/users/${user.id}/stronghold-payments`}
                    style={{ color: colors.black }}
                  >
                    WS Contribution
                  </Link>
                </Typography>
                <div className={classes.label}>
                  Count: {Number(user.shpay_count)}
                </div>
                <div className={classes.label}>
                  Total: ${Number(user.shpay_total).toFixed(2)}
                </div>
                {user.shpay_created ? (
                  <div style={{ margin: '10px' }}>
                    Latest: {user.shpay_created.substring(0, 10)}
                  </div>
                ) : ''}
              </div>

              <div className={classes.section}>
                <Typography variant="h5">
                  <Link
                    to={`/users/${user.id}/clients`}
                    style={{ color: colors.black }}
                  >
                    Clients
                  </Link>
                </Typography>
                <div className={classes.label}>
                  Count: {Number(user.client_count)}
                </div>
                {user.client_created ? (
                  <div style={{ margin: '10px' }}>
                    Latest: {user.client_created.substring(0, 10)}
                  </div>
                ) : ''}
              </div>

              <div className={classes.section}>
                <Typography variant="h5">
                  <Link
                    to={`/users/${user.id}/chargebacks`}
                    style={{ color: colors.black }}
                  >
                    Chargebacks
                  </Link>
                </Typography>
                <div className={classes.label}>
                  Balance: {Number(user.chargebackBalance).toFixed(2)}
                </div>
                <div className={classes.label}>
                  Chargeback Count: {Number(user.chargeback_count)}
                </div>
                <div className={classes.label}>
                  Total: ${Number(user.chargeback_total).toFixed(2)}
                </div>
                {user.chargeback_created ? (
                  <div style={{ margin: '10px' }}>
                    Latest: {user.chargeback_created.substring(0, 10)}
                  </div>
                ) : ''}
              </div>

              <div className={classes.section}>
                <Typography variant="h5">
                  <Link
                    to={`/users/${user.id}/override_details_to`}
                    style={{ color: colors.black }}
                  >
                    WS Pay Details Downline
                  </Link>
                </Typography>
                <div className={classes.label}>
                  Count: {Number(user.op_down_count || 0)}
                </div>
                <div className={classes.label}>
                  Total: ${Number(user.op_down_total || 0).toFixed(2)}
                </div>
                {user.op_down_created ? (
                  <div style={{ margin: '10px' }}>
                    Latest: {user.op_down_created.substring(0, 10)}
                  </div>
                ) : ''}
              </div>

              <div className={classes.section}>
                <Typography variant="h5">
                  <Link
                    to={`/users/${user.id}/override_details_from`}
                    style={{ color: colors.black }}
                  >
                    WS Pay Details Upline
                  </Link>
                </Typography>
                <div className={classes.label}>
                  Count: {Number(user.op_up_count || 0)}
                </div>
                <div className={classes.label}>
                  Total: ${Number(user.op_up_total || 0).toFixed(2)}
                </div>
                {user.op_up_created ? (
                  <div style={{ margin: '10px' }}>
                    Latest: {user.op_up_created.substring(0, 10)}
                  </div>
                ) : ''}
              </div>

            </div>
          </div>
        ) : (
          <div className={classes.loadingCircle}>
            <CircularProgress size={100} />
          </div>
        )}
      </div>
    </Layout>
  );
};

export default User;
