/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-named-as-default */
import React from 'react';

import CapitalTypes from '../pages/capital_types';
import Carriers from '../pages/carriers';
import Custodians from '../pages/custodians';
import ProductTypes from '../pages/product_types';
import Sources from '../pages/sources';

import IncomeSources from '../pages/client_income_sources';
import AssetTypes from '../pages/client_asset_types';
import AnnuityTypes from '../pages/client_annuity_types';
import DebtTypes from '../pages/client_debt_types';
import HoldingTypes from '../pages/client_holding_types';
import LifePolicyTypes from '../pages/client_insurance_types';
import InsuranceTypes from '../pages/client_term_insurance_types';
import FundTypes from '../pages/client_fund_types';
import RealEstateTypes from '../pages/client_real_estate_types';
import EmployerPlanTypes from '../pages/client_employer_plan_types';
import EmploymentStatusTypes from '../pages/client_employment_status_types';
import EmploymentOccupationTypes from '../pages/client_employment_occupation_types';
import TaxFilingStatusTypes from '../pages/client_tax_filing_status_types';
import Genders from '../pages/genders';
import TrustedAdvisors from '../pages/trusted_advisors';
import Relationships from '../pages/relationship_types';
import TrustTypes from '../pages/client_trust_types';
import TrustDistinctionTypes from '../pages/client_trust_distinction_types';
import Tags from '../pages/tags';

export const GENERAL_CONFIG = [
  { name: 'Carriers', component: <Carriers /> },
  { name: 'Sources', component: <Sources /> },
  { name: 'Capital Types', component: <CapitalTypes /> },
  { name: 'Custodians', component: <Custodians /> },
  { name: 'BSF Product Types', component: <ProductTypes /> },
  { name: 'Agent Tags', component: <Tags /> },
];
export const CLIENTS_CONFIG = [
  { name: 'Income Sources', component: <IncomeSources /> },
  { name: 'Asset Types', component: <AssetTypes /> },
  { name: 'Debt Types', component: <DebtTypes /> },
  { name: 'Holding Types', component: <HoldingTypes /> },
  { name: 'Annuity Types', component: <AnnuityTypes /> },
  { name: 'Life Policy Types', component: <LifePolicyTypes /> },
  { name: 'Insurance Types', component: <InsuranceTypes /> },
  { name: 'Fund Types', component: <FundTypes /> },
  { name: 'Real Estate Types', component: <RealEstateTypes /> },
  { name: 'Employer Plan Types', component: <EmployerPlanTypes /> },
  { name: 'Employment Status Types', component: <EmploymentStatusTypes /> },
  { name: 'Employment Occupation Types', component: <EmploymentOccupationTypes /> },
  { name: 'Tax Filing Status Types', component: <TaxFilingStatusTypes /> },
  { name: 'Genders', component: <Genders /> },
  { name: 'Trusted Advisors', component: <TrustedAdvisors /> },
  { name: 'Relationships', component: <Relationships /> },
  { name: 'Trust Types', component: <TrustTypes /> },
  { name: 'Trust Distinction Types', component: <TrustDistinctionTypes /> },
];
